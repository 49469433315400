var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-6"},[_c('Filters',{class:_vm.$style.filters,attrs:{"disabled":_vm.loading},on:{"filter_update":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"btn-outer",fn:function(){return [_c('v-btn',{class:[_vm.$style.btnOuter, 'mb-0'],attrs:{"text":""},on:{"click":function($event){_vm.cols_dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-tune")]),_c('span',[_vm._v("Настройка полей")])],1)]},proxy:true}])}),_vm._l((_vm.filtered_cols),function(header,idx){return _c('ResizeCols',{key:idx,attrs:{"header":header,"storage_name":_vm.cols_storage_name}})}),_c('Table',{attrs:{"headers":_vm.filtered_cols,"items":_vm.items,"options":_vm.tableOptions,"custom-sort":_vm.prevent_sort,"loading":_vm.loading,"footer-props":_vm.footerProps,"disable-sort":_vm.loading,"no-data-text":"Сертификаты, удовлетворяющие выбранным параметрам, отсутствуют","server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.course_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.course ? item.course.name : '')+" ")])]}},{key:"item.user_surname",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.evented_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.evented_date))+" ")])]}},{key:"item.expiry_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.expiry_date))+" ")])]}},{key:"item.cert_validity",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm.translate_validity(item.cert_validity))+" ")])]}},{key:"item.course_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.course_type ? item.course_type.name : '')+" ")])]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.result)+" ")])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.paid)+" ")])]}},{key:"item.external_id",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.external_id)+" ")])]}}],null,true)})],2),_c('CustomColsDialog',{attrs:{"cols":_vm.cols,"cols_list":_vm.cols,"storage_name":_vm.cols_storage_name,"default_cols":_vm.cols_default},on:{"update:cols_list":function($event){_vm.cols=$event},"save_cols":function($event){_vm.cols_dialog = false},"cancel_dialog":function($event){_vm.cols_dialog = false},"close":function($event){_vm.cols_dialog = false}},model:{value:(_vm.cols_dialog),callback:function ($$v) {_vm.cols_dialog=$$v},expression:"cols_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }